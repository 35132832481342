import "./post.css";
import SinglePost from "../../components/singlePost/SinglePost";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/Context";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SubscribeOrSharePopup from "../../components/subscribeOrSharePopup/subscribeOrSharePopup";
import SignUpPopup from "../../components/popUps/SignUpPopup";
import axios from "axios";

export default function Post() {
  const { handleViewIncrement, userViews, user } = useContext(Context);
  const location = useLocation();
  const articleId = location.pathname.split("/")[1];

  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const popupRef = useRef(null);

  // Fetch User Subscription Details
  const fetchUserSubscription = async () => {
    try {
      const res = await axios.get(`/users/${user.userId}`);
      if (res.status === 200) {
        const { subscription } = res.data;
        if (subscription.plan !== "plan") {
          setIsSubscribed(true);
        }
      } else {
        toast.info(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching user subscription details", error);
      toast.error("Error fetching user subscription details!");
    }
  };

  // Close SubscribeOrSharePopup if clicked outside
  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowSubscribePopup(false);
    }
  };

  // Add/Remove event listener for closing SubscribeOrSharePopup
  useEffect(() => {
    if (showSubscribePopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showSubscribePopup]);

  // Check if user is subscribed and handle popups accordingly
  useEffect(() => {
    handleViewIncrement(articleId);

    if (user) fetchUserSubscription();

    const shouldHidePopups = isSubscribed || user?.role > 3;

    if (shouldHidePopups) {
      setShowSignUpPopup(false);
      setShowSubscribePopup(false);
    } else {
      if (userViews > 5) {
        setShowSignUpPopup(true);
        setShowSubscribePopup(false);
      } else if (!user || user === null) {
        setShowSignUpPopup(true);
        setShowSubscribePopup(false);
      } else {
        const timer = setTimeout(() => {
          setShowSignUpPopup(false);
          setShowSubscribePopup(true);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }
  }, [user, isSubscribed, userViews, articleId, handleViewIncrement]);

  return (
    <div className="single">
      {showSubscribePopup && (
        <SubscribeOrSharePopup
          ref={popupRef}
          onClose={() => setShowSubscribePopup(false)}
        />
      )}
      {showSignUpPopup && <SignUpPopup />}
      <SinglePost />
    </div>
  );
}
