import React, { useContext, useEffect, useState } from "react";
import "./notSubscribed.css";
import axios from "axios";
import { Context } from "../../../context/Context";
import { toast } from "react-toastify";

const NotSubscribed = () => {
  const { user, token } = useContext(Context);
  const [subscription, setSubscription] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    const monthlyCard = document.querySelector(".subscription-card.monthly");
    const annuallyCard = document.querySelector(".subscription-card.annually");

    const handleClick = (event) => {
      monthlyCard.classList.remove("selected");
      annuallyCard.classList.remove("selected");
      event.currentTarget.classList.add("selected");

      if (event.currentTarget.classList.contains("monthly")) {
        setSelectedPlan("monthly");
      } else if (event.currentTarget.classList.contains("annually")) {
        setSelectedPlan("annual");
      } else {
        setSelectedPlan(null);
      }
    };

    if (monthlyCard && annuallyCard) {
      // Add event listener to monthly card
      monthlyCard.addEventListener("click", handleClick);

      // Add event listener to annually card
      annuallyCard.addEventListener("click", handleClick);
    }

    return () => {
      if (monthlyCard && annuallyCard) {
        monthlyCard.removeEventListener("click", handleClick);
        annuallyCard.removeEventListener("click", handleClick);
      }
    };
  }, []);

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await axios.get(`users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const planType = res.data.subscription.plan;
        const subscriptionId = res.data.subscription.subscriptionId;
        setSubscription({ plan: planType, subscriptionId: subscriptionId });
      } catch (err) {
        console.error(err);
        toast.error("Error Fetching Subscription Details!");
      }
    };
    getSubscription();
  }, [user.userId]);

  useEffect(() => {
    const getSubscriptionDetails = async () => {
      if (!subscription.subscriptionId || subscription.subscriptionId === '123') {
        return;
      }

      try {
        const res = await axios.get(`/stripe/get-subscription/${subscription.subscriptionId}`, { headers: { 'Authorization': `Bearer ${token}` } });
        
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const endDate = res.data.subscription.currentPeriodEnd;
        const planInterval = res.data.subscription.planInterval;
        setSubscriptionDetails({ endDate: endDate, planInterval: planInterval });
      } catch (err) {
        console.error("Error getting Subscription Details " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };
    if (subscription.subscriptionId) {
      getSubscriptionDetails();
    }
  }, [subscription.subscriptionId]);

  const handleSubscribe = async (plan) => {
    try {
      const userId = user ? user._id : null;
      
      const res = await axios.post("/stripe/create-checkout-session", {
        plan,
        userId,
      },  {headers: {'Authorization':`Bearer ${token}`}});
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      const session = await res.data;
      window.location.href = session.url;
    } catch (error) {
      console.error("Error creating checkout session: ", error);
      toast.error("Error Checking Out!");
    }
  };

  return (
    <>
      <div className="not-subscribed">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {subscription.subscriptionId !== '123' && (
            <>
              <h1 style={{ fontSize: "24px", fontWeight: "700" }}>Current Plan: Not Active</h1>
              <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
                Subscription ended: {subscriptionDetails.endDate}
              </h1>
              <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
                Previous subscription: {subscriptionDetails.planInterval === "month" ? "Monthly" : "Annual"}
              </h1>
              <div className="horizontal_line"></div>
            </>
          )}
          <h1
            style={{ fontSize: "36px", fontWeight: "700", marginTop: "10px" }}
          >
            Choose the plan that's right for you!
          </h1>
          <p style={{ fontSize: "25px", fontWeight: "500" }}>
            Choose the membership subscription that best fits your needs!
          </p>

          <div className="not-subscribed-cards">
            <div className="not-subscribed-card monthly">
              <div style={{ paddingLeft: "25px" }}>
                <h2 style={{ fontSize: "25px", fontWeight: 700 }}>
                  MONTHLY PLAN
                </h2>
                <p style={{ fontSize: "40px", fontWeight: 700 }}>
                  $10
                  <span style={{ fontSize: "20px", fontWeight: 400 }}> CAD</span>
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    marginBottom: "10px",
                  }}
                >
                  / Month
                </p>
                <p style={{ fontSize: "20px", fontWeight: 400 }}>
                  $2.50 per week
                </p>
                <p style={{ fontSize: "20px", fontWeight: 400 }}>
                  Billed Monthly
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <button
                  style={{
                    height: "63px",
                    width: "277px",
                    fontSize: "28px",
                    fontWeight: "700",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none",
                    backgroundColor: "#B1E5F2",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSubscribe("monthly")}
                >
                  Subscribe Now!
                </button>
              </div>
            </div>

            <div className="not-subscribed-card annually">
              <div style={{ position: "relative", paddingLeft: "25px" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "-50px",
                    right: "-90px",
                    width: "219px",
                    height: "61px",
                    backgroundColor: "#C9CDD3", // Example color, adjust as needed
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "700",
                    borderRadius: "10px",
                    fontSize: "24px",
                    fontWeight: "700",
                  }}
                >
                  3 MONTHS FREE !
                </div>
                <h2 style={{ fontSize: "25px", fontWeight: 700 }}>
                  ANNUAL PLAN
                </h2>
                <p style={{ fontSize: "40px", fontWeight: 700 }}>
                  $90
                  <span style={{ fontSize: "20px", fontWeight: 400 }}> CAD</span>
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    marginBottom: "10px",
                  }}
                >
                  / Year
                </p>
                <p style={{ fontSize: "20px", fontWeight: 400 }}>
                  $1.75 per week
                </p>
                <p style={{ fontSize: "20px", fontWeight: 400 }}>
                  Billed annually
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <button
                  style={{
                    height: "63px",
                    width: "277px",
                    fontSize: "28px",
                    fontWeight: "700",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none",
                    backgroundColor: "#B1E5F2",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSubscribe("annual")}
                >
                  Subscribe Now!
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          style={{
            marginTop: "40px",
            backgroundColor: "#272635",
            color: "#B1E5F2",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            marginLeft: "470px",
            height: "67px",
            width: "212px",
            fontSize: "28px",
          }}
          onClick={handleSubscribe}
        >
          SAVE
        </button>
      </div>
    </>
  );
};

export default NotSubscribed;
