import { useEffect, useState } from "react";
import "./singleAuthor.css";
import React from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { decodeHtml } from "../../utils/utils";
import Posts from "../posts/Posts";

export default function SingleAuthor() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [author, setAuthor] = useState({ profilePic: "" });
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getAuthor = async () => {
      try {
        const res = await axios.get("/users/" + path);

        if (res.status !== 200) {
          toast.info(res.data.message);
        }

        if (res.data.avatar_urls["full"] != null) {
          res.data.profilePic =
            process.env.REACT_APP_AWS_S3_IMAGE_URL +
            res.data.avatar_urls["full"];
        }

        setAuthor(res.data);
      } catch (err) {
        console.error("Error fetching author" + err);
        toast.error("Error Fetching Writer!");
      }
    };

    getAuthor();
  }, [path]);

  useEffect(() => {
    if (author && author.username) {
      const getPosts = async () => {
        try {
          const res = await axios.get("/posts/profile/" + author.username);

          if (res.status !== 200) {
            toast.info(res.data.message);
          }
          setPosts(res.data);
        } catch (err) {
          console.error("Error fetching posts");
          toast.error("Error Fetching Posts!");
        }
      };

      getPosts();
    }
  }, [author]);

  return (
    <div className="single-author-wrapper">
      <div className="divider"></div>
      <div className="author-section">
        <div className="author-image">
          <img src={author.profilePic} alt="Author" />
        </div>
        <div className="author-bio">
          <h2>{decodeHtml(author.user_name)}</h2>
          <p>{decodeHtml(author.desc)}</p>
        </div>
      </div>

      <div className="divider"></div>
      <div className="posted-article">
        <h2 className="posted-article-text">Posted Articles</h2>
        <div className="hide-post-elements">
          <Posts posts={posts} />
        </div>
      </div>
    </div>
  );
}
