import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Context } from "../../context/Context";
import Header from "../../components/header/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { decodeHtml } from "../../utils/utils";

export default function Home() {
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [category, setCategory] = useState([]);
  const { user } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await axios.get("/posts/top-posts");

        if (res.status == 200) {
          setPosts(res.data);
        }
      } catch (err) {
        console.error("Error fetching posts! " + err);
        toast.error("Error Fetching Posts!");
      }
    };

    const fetchUsers = async () => {
      try {
        const res = await axios.get("/posts/top-users");

        if (res.status == 200) {
          setUsers(res.data);
        }
      } catch (err) {
        console.error("Error fetching posts! " + err);
        toast.error("Error Fetching Posts!");
      }
    };

    const fetchCategories = async () => {
      try {
        const res = await axios.get("/categories/three");

        if (res.status == 200) {
          setCategory(res.data);
        }
      } catch (err) {
        console.error("Error fetching posts! " + err);
        toast.error("Error Fetching Posts!");
      }
    };
    fetchCategories();
    fetchUsers();
    fetchPosts();
  }, []);

  return (
    <>
      <div className="BodyHome">
        <div className="background"></div>
        <div className="headerHome">
          <div className="logoHome">
            <Link to="/">
              <img
                src={
                  process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL +
                  "/FLOW_logo.png"
                }
                alt="Logo"
              />
            </Link>
          </div>
        </div>
        <Header />
        <div className="container-home">
          <div className="content">
            <div className="centered-text">
              <div className="intro-text">
                <p>IGNITE CURIOSITY.</p>
                <p>SPARK IMAGINATION.</p>
              </div>
              <button className="centered-btn">
                <Link
                  to="/articles"
                  style={{ textDecoration: "none", color: "#B1E5F2" }}
                >
                  Browse Articles
                </Link>
              </button>
            </div>
          </div>
        </div>

        <div className="containertwo">
          <div className="section">
            <div className="image">
              <div className="carousel-container">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  autoPlay
                  renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    hasPrev && (
                      <button
                        type="button"
                        className="control-arrow control-prev"
                        onClick={clickHandler}
                      >
                        &#10094;
                      </button>
                    )
                  }
                  renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    hasNext && (
                      <button
                        type="button"
                        className="control-arrow control-next"
                        onClick={clickHandler}
                      >
                        &#10095;
                      </button>
                    )
                  }
                >
                  {posts.map((p) => (
                    <div>
                      <img
                        src={
                          p.photo.startsWith("http")
                            ? p.photo
                            : `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${p.photo}`
                        }
                        alt={p.post_slug}
                        className="carousel-image"
                      />
                      <div className="caption">
                        {p.title}:
                        <br />
                        Embracing Self-Expression Creatively
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="text">
              <div className="text-button">
                <h1 style={{ fontSize: "36px" }}>
                  Embark on a Literary Journey
                </h1>
                <p
                  style={{
                    paddingLeft: "20px",
                    fontSize: "24px",
                    width: "405px",
                  }}
                >
                  Dive into our expansive collection of articles that blend
                  reality and imagination. From groundbreaking discoveries to
                  creative fiction, our pieces captivate and enlighten.
                </p>
                <button>
                  <Link
                    to="/articles"
                    style={{ textDecoration: "none", color: "#B1E5F2" }}
                  >
                    Explore All Articles
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="image">
              <div className="text-button">
                <h1 style={{ fontSize: "36px" }}>
                  Discover Your Next Obsession
                </h1>
                <p
                  style={{
                    paddingLeft: "40px",
                    fontSize: "24px",
                    width: "405px",
                  }}
                >
                  Our collection spans a myriad of genres, ensuring there's
                  something for every curious mind. Whether you're into travel,
                  science, nature, or beyond, we've got you covered.
                </p>
                <button>
                  <Link
                    to="/categories"
                    style={{ textDecoration: "none", color: "#B1E5F2" }}
                  >
                    Discover Genres
                  </Link>
                </button>
              </div>
            </div>
            <div className="text">
              <div className="carousel-container">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  autoPlay
                  renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    hasPrev && (
                      <button
                        type="button"
                        className="control-arrow control-prev"
                        onClick={clickHandler}
                      >
                        &#10094;
                      </button>
                    )
                  }
                  renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    hasNext && (
                      <button
                        type="button"
                        className="control-arrow control-next"
                        onClick={clickHandler}
                      >
                        &#10095;
                      </button>
                    )
                  }
                >
                  {category.map((c) => (
                    <div>
                      <img
                        src={
                          c.cat_image
                            ? `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${c.cat_image}`
                            : `https://ui-avatars.com/api/?name=${c.cat_slug}&background=random`
                        }
                        alt={c.cat_slug}
                        className="carousel-image"
                      />
                      <div className="caption">{decodeHtml(c.cat_name)}</div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="image">
              <div className="carousel-container">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  autoPlay
                  renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    hasPrev && (
                      <button
                        type="button"
                        className="control-arrow control-prev"
                        onClick={clickHandler}
                      >
                        &#10094;
                      </button>
                    )
                  }
                  renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    hasNext && (
                      <button
                        type="button"
                        className="control-arrow control-next"
                        onClick={clickHandler}
                      >
                        &#10095;
                      </button>
                    )
                  }
                >
                  {users.map((u) => (
                    <div>
                      <img
                        src={
                          u.avatar_urls
                            ? `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${u.avatar_urls["full"]}`
                            : `https://ui-avatars.com/api/?name=${u.profilePic}&background=random`
                        }
                        alt={u.user_slug}
                        className="carousel-image"
                      />
                      <div className="caption">{u.user_name}</div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="text">
              <div className="text-button">
                <h1 style={{ fontSize: "36px" }}>
                  Meet the Minds Behind the Words
                </h1>
                <p
                  style={{
                    paddingLeft: "70px",
                    fontSize: "24px",
                    width: "405px",
                  }}
                >
                  Our writers are the heartbeat of our publication, bringing
                  diverse perspectives and expertise to the forefront. Meet the
                  talented writers who craft the content you love.
                </p>
                <button>
                  <Link
                    to="/authors"
                    style={{ textDecoration: "none", color: "#B1E5F2" }}
                  >
                    Meet Our Writers
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="subscribe-section">
          <h1
            style={{
              textAlign: "center",
              fontSize: "48px",
              fontWeight: "500",
              paddingBottom: "10px",
            }}
          >
            Get Free Access!
          </h1>
          <p
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontSize: "32px",
              fontWeight: "400",
            }}
          >
            Sign up and receive access to 5 free articles!
          </p>
          <div style={{ alignItems: "center", paddingTop: "15px" }}>
            <input
              type="email"
              className="signup-email"
              placeholder={user ? user.email : "Email Address"}
            />
            <button
              style={{
                backgroundColor: "#272635",
                color: "#FFFCFC",
                height: "75px",
                width: "209px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                fontSize: "36px",
                fontWeight: "700",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              onClick={async () => {
                try {
                  if (user && user.email) {
                    const res = await axios.put(`/users/${user._id}`, {
                      isMember: true,
                    });
                    if (res.status !== 200) {
                      toast.info("Something went wrong!");
                    } else {
                      toast.success("Subscribed!");
                    }
                  } else {
                    toast.info("You Need To Login First!");
                    navigate("/login");
                  }
                } catch (error) {
                  console.error("Error:", error);
                }
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
