import React, { useEffect, useState, useContext } from "react";
import "./manageSubscription.css";
import axios from "axios";
import { Context } from "../../../context/Context";
import { toast } from "react-toastify";

const ManageSubscription = ({ onSubscriptionCancelled }) => {
  const { user, token } = useContext(Context);
  const [subscription, setSubscription] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cancelChecked, setCancelChecked] = useState(false);

  useEffect(() => {
    const monthlyCard = document.querySelector(".subscription-card.monthly");
    const annuallyCard = document.querySelector(".subscription-card.annually");

    const handleClick = (event) => {
      monthlyCard.classList.remove("selected");
      annuallyCard.classList.remove("selected");
      event.currentTarget.classList.add("selected");

      if (event.currentTarget.classList.contains("monthly")) {
        setSelectedPlan("Monthly Plan");
      } else if (event.currentTarget.classList.contains("annually")) {
        setSelectedPlan("Annual Plan");
      } else {
        setSelectedPlan(null);
      }
    };

    if (monthlyCard && annuallyCard) {
      monthlyCard.addEventListener("click", handleClick);
      annuallyCard.addEventListener("click", handleClick);
    }

    return () => {
      if (monthlyCard && annuallyCard) {
        monthlyCard.removeEventListener("click", handleClick);
        annuallyCard.removeEventListener("click", handleClick);
      }
    };
  }, []);

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const planType = res.data.subscription.plan;
        const subscriptionId = res.data.subscription.subscriptionId;
        setSubscription({ plan: planType, subscriptionId: subscriptionId });
      } catch (err) {
        console.error("Error getting Subscription! " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };
    getSubscription();
  }, [user.userId]);

  useEffect(() => {
    const getSubscriptionDetails = async () => {
      if (!subscription.subscriptionId) {
        toast.error("SubscriptionId is missing!");
        return;
      }

      try {
        const res = await axios.get(
          `/stripe/get-subscription/${subscription.subscriptionId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log("Subscription details response:", res.data);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const startDate = res.data.subscription.currentPeriodStart;
        const endDate = res.data.subscription.currentPeriodEnd;
        const paymentMethod = res.data.subscription.paymentMethod;
        setSubscriptionDetails({
          startDate: startDate,
          endDate: endDate,
          paymentMethod: paymentMethod,
        });
      } catch (err) {
        console.error("Error getting Subscription Details " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };
    if (subscription.subscriptionId) {
      getSubscriptionDetails();
    }
  }, [subscription.subscriptionId]);

  const handleSubscribe = async () => {
    if (!selectedPlan) {
      toast.error("Please select a plan to subscribe.");
      return;
    }
    try {
      const userId = user ? user._id : null;
      const currentPlan = subscription.plan;
      if (currentPlan === selectedPlan) {
        toast.info("You are already subscribed to this plan.");
        return;
      }
      if (subscription.subscriptionId) {
        const res = await axios.post(
          "/stripe/update-subscription",
          {
            subscriptionId: subscription.subscriptionId,
            plan: selectedPlan === "Monthly Plan" ? "monthly" : "annual",
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (res.status !== 200) {
          toast.info(res.data.msg);
        } else {
          toast.success("Subscription updated successfully!");
          setSubscription({
            plan: selectedPlan,
            subscriptionId: subscription.subscriptionId,
          });
        }
      } else {
        const res = await axios.post(
          "/stripe/create-checkout-session",
          {
            plan: selectedPlan === "Monthly Plan" ? "monthly" : "annual",
            userId,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res.status !== 200) {
          toast.info(res.data.msg);
        } else {
          const session = await res.data;
          window.location.href = session.url;
        }
      }
    } catch (error) {
      console.error("Error creating checkout session: ", error);
      toast.error("Error Checking Out!");
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const res = await axios.post(
        "/stripe/cancel-subscription",
        {
          subscriptionId: subscription.subscriptionId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (res.status === 200) {
        toast.success("Subscription cancelled successfully!");
        setSubscription({ plan: null, subscriptionId: null });
        onSubscriptionCancelled(false);
      } else {
        toast.error("Failed to cancel subscription.");
      }
    } catch (error) {
      console.error("Error cancelling subscription: " + error);
      toast.error("Failed to cancel subscription.");
    }
  };

  const handleCheckboxChange = () => {
    setCancelChecked(!cancelChecked);
  };

  return (
    <>
      <div className="manage-subscription">
        <h1 style={{ fontSize: "24px", fontWeight: "700" }}>
          Current Plan: Active
        </h1>
        <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
          {subscription.plan === "cancelled" ? <br /> : subscription.plan}{" "}
          {subscription.plan === "Monthly Plan"
            ? "$10/Month"
            : subscription.plan === "Annual Plan"
            ? "$90/Year"
            : "Not Subscribed"}
        </h1>
        <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
          Subscription began: {subscriptionDetails?.startDate} Renewal date:{" "}
          {subscriptionDetails?.endDate}
        </h1>
      </div>
      <div className="horizontal_line"></div>

      <div className="manage-subscription">
        <h1 style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}>
          Change Plan:
        </h1>
        <div className="subscription-cards">
          <div className="subscription-card monthly">
            <h1 style={{ marginTop: "15px", marginLeft: "15px" }}>
              <span className="button-like-text">Monthly</span>
            </h1>
            <p
              style={{
                marginTop: "15px",
                marginLeft: "15px",
                fontSize: "18px",
              }}
            >
              A recurring payment plan that charges once per month.
            </p>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "400",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              $10
              <span style={{ fontSize: "18px", fontWeight: "400" }}>
                CAD / Month
              </span>
            </p>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              $2.50 per week
            </p>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                marginBottom: "30px",
                marginLeft: "15px",
              }}
            >
              Billed Monthly
            </p>
          </div>
          <div className="subscription-card annually">
            <h1 style={{ marginTop: "15px", marginLeft: "15px" }}>
              <span className="button-like-text">Annually</span>
            </h1>
            <p
              style={{
                marginTop: "15px",
                marginLeft: "15px",
                fontSize: "18px",
              }}
            >
              A recurring payment plan that charges one a year.
            </p>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "400",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              $90
              <span style={{ fontSize: "18px", fontWeight: "400" }}>
                CAD / Year
              </span>
            </p>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              $1.75 per week
            </p>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                marginBottom: "30px",
                marginLeft: "15px",
              }}
            >
              Billed Annually
            </p>
          </div>
        </div>
        <button
          type="submit"
          style={{
            marginTop: "40px",
            backgroundColor: "#B1E5F2",
            color: "black",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            marginLeft: "470px",
            height: "44px",
            width: "201px",
            fontSize: "20px",
          }}
          onClick={handleSubscribe}
        >
          Change Plan
        </button>
      </div>

      <div className="horizontal_line"></div>

      <div className="manage-subscription">
        <h1 style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}>
          Payment Methods:
        </h1>
        <h3>
          {subscriptionDetails.paymentMethod?.brand.toUpperCase()} ending in{" "}
          {subscriptionDetails.paymentMethod?.last4}
        </h3>
        <h3>
          Expires {subscriptionDetails.paymentMethod?.exp_month}/
          {subscriptionDetails.paymentMethod?.exp_year}
        </h3>
      </div>

      <div className="horizontal_line"></div>

      <div className="manage-subscription">
        <h1 style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}>
          Payment Method:
        </h1>

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
        >
          <div className="CancelCheckBox">
            <input
              type="checkbox"
              id="payment"
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="payment"
              style={{
                marginLeft: "15px",
                boxShadow: "none",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              Cancel my subscription
            </label>
          </div>
          <button
            type="submit"
            onClick={handleCancelSubscription}
            disabled={!cancelChecked}
            style={{
              backgroundColor: cancelChecked ? "#B1E5F2" : "#A6A6A8",
              color: "black",
              border: "none",
              borderRadius: "10px",
              cursor: "pointer",
              marginLeft: "242px",
              width: "238px",
              height: "44px",
              fontSize: "20px",
            }}
          >
            Cancel Subscription
          </button>
        </div>
      </div>
    </>
  );
};

export default ManageSubscription;

// import React, { useContext, useEffect, useState } from "react";
// import "./manageSubscription.css";
// import axios from "axios";
// import { Context } from "../../../context/Context";
// import { toast } from "react-toastify";

// const ManageSubscription = () => {
//   const { user } = useContext(Context);
//   const [subscription, setSubscription] = useState({});
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [cancelChecked, setCancelChecked] = useState(false);

//   useEffect(() => {
//     const monthlyCard = document.querySelector(".subscription-card.monthly");
//     const annuallyCard = document.querySelector(".subscription-card.annually");

//     const handleClick = (event) => {
//       monthlyCard.classList.remove("selected");
//       annuallyCard.classList.remove("selected");
//       event.currentTarget.classList.add("selected");

//       if (event.currentTarget.classList.contains("monthly")) {
//         setSelectedPlan("Monthly Plan");
//       } else if (event.currentTarget.classList.contains("annually")) {
//         setSelectedPlan("Annual Plan");
//       } else {
//         setSelectedPlan(null);
//       }
//     };

//     if (monthlyCard && annuallyCard) {
//       // Add event listener to monthly card
//       monthlyCard.addEventListener("click", handleClick);

//       // Add event listener to annually card
//       annuallyCard.addEventListener("click", handleClick);
//     }

//     return () => {
//       if (monthlyCard && annuallyCard) {
//         monthlyCard.removeEventListener("click", handleClick);
//         annuallyCard.removeEventListener("click", handleClick);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const getSubscription = async () => {
//       try {
//         const res = await axios.get(`users/${user.userId}`);
//         if (res.status !== 200) {
//           toast.info(res.data.msg);
//         }
//         const planType = res.data.subscription.plan;
//         const subscriptionId = res.data.subscription.subscriptionId;
//         setSubscription({ plan: planType, subscriptionId: subscriptionId });
//       } catch (err) {
//         console.error("Error getting Subscription! " + err);
//         toast.error("Error Fetching Subscription Details!");
//       }
//     };
//     getSubscription();
//   }, [user.userId]);

//   const handleSubscribe = async () => {
//     if (!selectedPlan) {
//       toast.error("Please select a plan to subscribe.");
//       return;
//     }
//     try {
//       const userId = user ? user.userId : null;
//       const currentPlan = subscription.plan;
//       if (currentPlan === selectedPlan) {
//         toast.info("You are already subscribed to this plan.");
//         return;
//       }
//       if (subscription.subscriptionId) {
//         const res = await axios.post("/stripe/update-subscription", {
//           subscriptionId: subscription.subscriptionId,
//           plan: selectedPlan === "Monthly Plan" ? "monthly" : "annual",
//         });

//         if (res.status !== 200) {
//           toast.info(res.data.msg);
//         } else {
//           toast.success("Subscription updated successfully!");
//           setSubscription({ plan: selectedPlan, subscriptionId: subscription.subscriptionId });
//         }
//       } else {
//         const res = await axios.post("/stripe/create-checkout-session", {
//           plan: selectedPlan === "Monthly Plan" ? "monthly" : "annual",
//           userId,
//         });
//         if (res.status !== 200) {
//           toast.info(res.data.msg);
//         } else {
//           const session = await res.data;
//           window.location.href = session.url;
//         }
//       }
//     } catch (error) {
//       console.error("Error creating checkout session: ", error);
//       toast.error("Error Checking Out!");
//     }
//   };

//   const handleCancelSubscription = async () => {
//     try {
//       const res = await axios.post("/stripe/cancel-subscription", {
//         subscriptionId: subscription.subscriptionId,
//       });

//       if (res.status === 200) {
//         toast.success("Subscription cancelled successfully!");
//         setSubscription({ plan: null, subscriptionId: null });
//       } else {
//         toast.error("Failed to cancel subscription.")
//       }
//     }catch(error){
//       console.error("Error cancelling subscription: " + error);
//       toast.error("Failed to cancel subscription.");
//     }
//   }

//   const handleCheckboxChange = () => {
//     setCancelChecked(!cancelChecked);
//   };

//   return (
//     <>
//       <div className="manage-subscription">
//         <h1 style={{ fontSize: "24px", fontWeight: "700" }}>Current Plan:</h1>
//         <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
//           {subscription.plan === "cancelled" ? <br /> : subscription.plan}
//         </h1>
//         <h1 style={{ fontSize: "20px", fontWeight: "400" }}>
//           {subscription.plan === "Monthly Plan"
//             ? "$10/Month"
//             : subscription.plan === "Annual Plan"
//             ? "$90/Year"
//             : "Not Subscribed"}
//         </h1>
//       </div>
//       <div className="horizontal_line"></div>

//       <div className="manage-subscription">
//         <h1 style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}>
//           Change Plan:
//         </h1>
//         <div className="subscription-cards">
//           <div className="subscription-card monthly">
//             <h1 style={{ marginTop: "15px", marginLeft: "15px" }}>
//               <span className="button-like-text">Monthly</span>
//             </h1>
//             <p
//               style={{
//                 marginTop: "15px",
//                 marginLeft: "15px",
//                 fontSize: "18px",
//               }}
//             >
//               A recurring payment plan that charges once per month.
//             </p>
//             <p
//               style={{
//                 fontSize: "24px",
//                 fontWeight: "400",
//                 marginTop: "15px",
//                 marginLeft: "15px",
//               }}
//             >
//               $10
//               <span style={{ fontSize: "18px", fontWeight: "400" }}>
//                 CAD / Month
//               </span>
//             </p>
//             <p
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "400",
//                 marginTop: "15px",
//                 marginLeft: "15px",
//               }}
//             >
//               $2.50 per week
//             </p>
//             <p
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "400",
//                 marginBottom: "30px",
//                 marginLeft: "15px",
//               }}
//             >
//               Billed Monthly
//             </p>
//           </div>
//           <div className="subscription-card annually">
//             <h1 style={{ marginTop: "15px", marginLeft: "15px" }}>
//               <span className="button-like-text">Annually</span>
//             </h1>
//             <p
//               style={{
//                 marginTop: "15px",
//                 marginLeft: "15px",
//                 fontSize: "18px",
//               }}
//             >
//               A recurring payment plan that charges one a year.
//             </p>
//             <p
//               style={{
//                 fontSize: "24px",
//                 fontWeight: "400",
//                 marginTop: "15px",
//                 marginLeft: "15px",
//               }}
//             >
//               $90
//               <span style={{ fontSize: "18px", fontWeight: "400" }}>
//                 CAD / Year
//               </span>
//             </p>
//             <p
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "400",
//                 marginTop: "15px",
//                 marginLeft: "15px",
//               }}
//             >
//               $1.75 per week
//             </p>
//             <p
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "400",
//                 marginBottom: "30px",
//                 marginLeft: "15px",
//               }}
//             >
//               Billed Annually
//             </p>
//           </div>
//         </div>
//         <button
//           type="submit"
//           style={{
//             marginTop: "40px",
//             backgroundColor: "#B1E5F2",
//             color: "black",
//             border: "none",
//             borderRadius: "10px",
//             cursor: "pointer",
//             marginLeft: "470px",
//             height: "44px",
//             width: "201px",
//             fontSize: "20px",
//           }}
//           onClick={handleSubscribe}
//         >
//           Change Plan
//         </button>
//       </div>

//       <div className="horizontal_line"></div>

//       <div className="manage-subscription">
//         <h1 style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}>
//           Payment Method:
//         </h1>
//         <h3>Payment Details</h3>
//       </div>

//       <div className="horizontal_line"></div>

//       <div className="manage-subscription">
//         <h1 style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}>
//           Payment Method:
//         </h1>

//         <div style={{display:"flex", alignItems:"center", marginTop:"15px", }}>
//           <div className="CancelCheckBox">
//             <input
//               type="checkbox"
//               id="payment"
//               onChange={handleCheckboxChange}

//             />
//             <label
//               htmlFor="payment"
//               style={{ marginLeft: "15px", boxShadow: "none", cursor:"pointer", marginBottom:"10px" }}
//             >
//               Cancel my subscription
//             </label>

//           </div>
//           <button
//             type="submit"
//             onClick={handleCancelSubscription}
//             disabled={!cancelChecked}
//               style={{
//                 backgroundColor: "#A6A6A8",
//                 color: "black",
//                 border: "none",
//                 borderRadius: "10px",
//                 cursor: "pointer",
//                 marginLeft: "242px",
//                 marginTop: "10px",
//                 width:"238px",
//                 height:"44px",
//                 fontSize:"20px"
//               }}
//             >
//               Cancel Subscription
//             </button>
//         </div>
//       </div>

//     </>
//   );
// };

// export default ManageSubscription;
