import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { decodeHtml } from "../../utils/utils";
import "./category.css";
import axios from "axios";
import Header from "../../components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Posts from "../../components/posts/Posts";
import { toast } from "react-toastify";

export default function Category() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [cats, setCats] = useState({});
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [recentlyPublished, setRecentlyPublished] = useState(false);
  const [oldestArticles, setOldestArticles] = useState(false);
  const [azSort, setAzSort] = useState(false);
  const [zaSort, setZaSort] = useState(false);
  const filterDialogRef = useRef(null);
  const filterButtonRef = useRef(null);
  const postsPerPage = 6;

  useEffect(() => {
    const getCats = async () => {
      try {
        const res = await axios.get("/categories/" + path);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setCats(res.data);
      } catch (err) {
        console.error("Error fetching Genres! " + err);
        toast.error("Error Fetching Genres!");
      }
    };

    getCats();
  }, [path]);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const res = await axios.get("/posts/category/" + cats.cat_slug);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setPosts(res.data);
      } catch (err) {
        console.error("Error Fetching Posts! " + err);
        toast.error("Error Fetching Posts!");
      }
    };

    getPosts();
  }, [cats]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDialogRef.current &&
        !filterDialogRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  const filterPosts = () => {
    let filteredPosts = [...posts];

    if (recentlyPublished) {
      filteredPosts = filteredPosts.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
    } else if (oldestArticles) {
      filteredPosts = filteredPosts.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (azSort) {
      filteredPosts = filteredPosts.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
    } else if (zaSort) {
      filteredPosts = filteredPosts.sort((a, b) =>
        b.title.localeCompare(a.title)
      );
    }

    return filteredPosts;
  };

  const currentPosts = filterPosts().slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterChange = (e) => {
    switch (e.target.name) {
      case "recentlyPublished":
        setRecentlyPublished(e.target.checked);
        setOldestArticles(false);
        setAzSort(false);
        setZaSort(false);
        break;
      case "oldestArticles":
        setRecentlyPublished(false);
        setOldestArticles(e.target.checked);
        setAzSort(false);
        setZaSort(false);
        break;
      case "azSort":
        setRecentlyPublished(false);
        setOldestArticles(false);
        setAzSort(e.target.checked);
        setZaSort(false);
        break;
      case "zaSort":
        setRecentlyPublished(false);
        setOldestArticles(false);
        setAzSort(false);
        setZaSort(e.target.checked);
        break;
      default:
        break;
    }
    setShowFilter(false); // Close the filter dialog after selecting a filter
  };

  return (
    <>
      <div className="BodyCategoryList">
        <div className="background-categoryList">
          <div className="text-overlay">{decodeHtml(cats.cat_name)}</div>
          <div className="separator-line"></div>
          <div className="text-below-line">
            <p>{cats.cat_desc}</p>
          </div>
        </div>
        <div className="headerHome" style={{ zIndex: "2" }}>
          <div className="logoHome">
            <Link to="/">
              <img
                src={
                  process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL +
                  "/FLOW_logo.png"
                }
                alt="Logo"
              />
            </Link>
          </div>
        </div>

        <Header />
      </div>

      <div className="card-container-cat">
        {posts.length > 0 ? (
          <>
            <button
              className="filter-btn"
              onClick={toggleFilter}
              ref={filterButtonRef}
            >
              Filter{" "}
              <FontAwesomeIcon icon={faFilter} style={{ marginLeft: "10px" }} />
            </button>

            {showFilter && (
              <div className="filter-dialog-category" ref={filterDialogRef}>
                <h2>Filter</h2>
                <div className="checkbox-group">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      name="recentlyPublished"
                      checked={recentlyPublished}
                      onChange={handleFilterChange}
                    />
                    <span className="checkmark"></span> Recently Published
                  </label>
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      name="oldestArticles"
                      checked={oldestArticles}
                      onChange={handleFilterChange}
                    />
                    <span className="checkmark"></span> Oldest Articles
                  </label>
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      name="azSort"
                      checked={azSort}
                      onChange={handleFilterChange}
                    />
                    <span className="checkmark"></span> A-Z
                  </label>
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      name="zaSort"
                      checked={zaSort}
                      onChange={handleFilterChange}
                    />
                    <span className="checkmark"></span> Z-A
                  </label>
                </div>
              </div>
            )}
            <Posts posts={currentPosts} />
            <div className="pagination">
              <button
                style={{
                  width: "50px",
                  height: "30px",
                  backgroundColor: "#B1E5F2",
                }}
                className="pagination-left-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button
                style={{
                  width: "50px",
                  height: "30px",
                  backgroundColor: "#B1E5F2",
                }}
                className="pagination-right-btn"
                onClick={nextPage}
                disabled={currentPosts.length < postsPerPage}
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <p>No Data.</p>
        )}
      </div>
    </>
  );
}
