// pages/Write.jsx
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Context } from "../../context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import "./write.css";
import { createSlug, decodeHtml } from "../../utils/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faPenToSquare,
  faTrashCan,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import SeoAnalyser from "./SeoAnalyser";

export default function Write() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [caption, setCaption] = useState("");
  const [url, setUrl] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [postSlug, setPostSlug] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [focusKeyphrase, setFocusKeyphrase] = useState("");
  const { user, token } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [articles, setArticles] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null);
  const [mainAuthor, setMainAuthor] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);
  const quillRef = useRef(null);

  const location = useLocation();
  const draftSlug = new URLSearchParams(location.search).get("draft");

  const loadArticles = async () => {
    try {
      const articlesRes = await axios.get(`/posts`);
      const sortedArticles = articlesRes.data.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setArticles(
        sortedArticles.filter((article) => article.status !== "draft")
      );
      setDrafts(sortedArticles.filter((article) => article.status === "draft"));
    } catch (err) {
      console.error("Error fetching articles", err);
    }
  };

  const handleEditDraft = (draftSlug) => {
    //setIsSidebarOpen(!isSidebarOpen);
    toggleSidebar();
    navigate(`/write?draft=${draftSlug}`);
  };

  const handleEditArticle = (articleSlug) => {
    //setIsSidebarOpen(!isSidebarOpen);
    toggleSidebar();
    navigate(`/write?draft=${articleSlug}`);
  };

  const handleDeleteArticle = async (articleId) => {
    try {
      await axios.delete(`posts/${articleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setArticles(articles.filter((article) => article._id !== articleId));
      toast.success("Article deleted successfully!");
    } catch (err) {
      console.error("Error deleting article", err);
      toast.error("Error deleting article");
    }
  };

  const handleDeleteDraft = async (draftId) => {
    try {
      await axios.delete(`posts/${draftId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDrafts(drafts.filter((draft) => draft._id !== draftId));
      toast.success("Draft deleted successfully!");
    } catch (err) {
      console.error("Error deleting draft", err);
      toast.error("Error deleting draft");
    }
  };

  const handleSwitchToPublish = async (draftId) => {
    try {
      const newArticle = {
        status: "published",
      };

      await axios.put(`/posts/${draftId}`, newArticle, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDrafts(drafts.filter((draft) => draft._id !== draftId));
      toast.success("Successfully switched to article!");
      loadArticles();
    } catch (err) {}
  };

  const handleSwitchToDraft = async (articleId) => {
    try {
      const newArticle = {
        status: "draft",
      };

      await axios.put(`/posts/${articleId}`, newArticle, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setArticles(articles.filter((art) => art._id !== articleId));
      toast.success("Successfully switched to draft!");
      loadArticles();
    } catch (err) {}
  };

  const getMainAuthor = async () => {
    const res = await axios.get(`/users`);
    const authors = res.data.filter((user) => user.isAuthor);
    const author = authors.find(
      (author) => author.user_name === mainAuthor.label
    );
    let authorId;
    if (author) {
      authorId = author.userId;
    }
    return authorId;
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(`/categories`);
      const categories = res.data.filter((cat) =>
        selectedCategories
          .map((category) => category.value)
          .includes(cat.cat_slug)
      );
      return categories;
    } catch (err) {
      console.error("Error fetching categories!", err);
    }
  };

  const getTags = async () => {
    try {
      const res = await axios.get(`/tags`);
      const tags = res.data.filter((tag) =>
        selectedTags.map((tag) => tag.value).includes(tag.tag_slug)
      );
      return tags;
    } catch (err) {
      console.error("Error fetching tags!", err);
    }
  };

  useEffect(() => {
    if (!user || !token) {
      navigate(-1);
      return;
    }
    const userRole = jwtDecode(token).role;
    if (userRole > 2) {
      setIsAuthorized(true);
    } else {
      navigate(-1);
    }
  }, [token, user, navigate]);

  useEffect(() => {
    loadArticles();
  }, []);

  useEffect(() => {
    const loadCategoriesAndTags = async () => {
      try {
        const authorsRes = await axios.get("/users");
        const authors = authorsRes.data.filter((user) => user.isAuthor);
        setAuthors(
          authors.map((user) => ({
            label: decodeHtml(user.user_name),
            value: decodeHtml(user.user_slug),
          }))
        );

        const categoriesRes = await axios.get(`/categories`);
        setCategories(
          categoriesRes.data.map((cat) => ({
            label: decodeHtml(cat.cat_name),
            value: decodeHtml(cat.cat_slug),
          }))
        );

        const tagsRes = await axios.get(`/tags`);
        setTags(
          tagsRes.data.map((tag) => ({
            label: decodeHtml(tag.tag_name),
            value: decodeHtml(tag.tag_slug),
          }))
        );
      } catch (err) {
        console.error("Error fetching categories or tags", err);
      }
    };

    const loadDraft = async (slug) => {
      try {
        const draftRes = await axios.get(`/posts/${slug}`);
        const draft = draftRes.data;
        setTitle(draft.title);
        setContent(draft.desc);
        setPostSlug(draft.post_slug);
        setSeoTitle(draft.seoTitle || "");
        setMetaDescription(draft.metaDescription || "");
        setFocusKeyphrase(draft.focusKeyphrase || "");
        setUrl(draft.url || "");
        setCaption(draft.photoCaption || "");
        setSelectedCategories(
          draft.categories.map((cat) => ({
            label: decodeHtml(cat.cat_name),
            value: decodeHtml(cat.cat_slug),
          }))
        );
        setSelectedAuthors(draft.authors);
        setSelectedTags(
          draft.tags.map((tag) => ({
            label: decodeHtml(tag.tag_name),
            value: decodeHtml(tag.tag_slug),
          }))
        );
        setCurrentArticleId(draft._id);
        setIsUpdating(true);
        const author = await axios.get(`/users/${draft.authorId}`);
        setMainAuthor({
          label: author.data.user_name,
          value: author.data.user_slug,
        });
        // Set the image
        if (draft.photo) {
          // If the image is a URL or a filename, you can set it accordingly
          // Assuming draft.photo is the URL or relative path to the image
          const imageUrl = `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${draft.photo}`;
          setFile(null);
          setImage(imageUrl);
        }
      } catch (err) {
        console.error("Error fetching draft", err);
      }
    };

    loadCategoriesAndTags();
    loadArticles();

    if (draftSlug) {
      loadDraft(draftSlug);
    }
  }, [draftSlug]);

  const validateFields = () => {
    if (
      !title ||
      !content ||
      !seoTitle ||
      !metaDescription ||
      !focusKeyphrase ||
      selectedCategories.length === 0 ||
      !mainAuthor
    ) {
      toast.error("Please fill in all required fields marked with *");
      return false;
    }
    return true;
  };

  const extractText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const handleSaveAsDraft = async () => {
    if (!validateFields()) return;
    const authorId = await getMainAuthor();
    const categories = await getCategories();
    const tags = await getTags();
    const plainDesc = extractText(content);
    const newArticle = {
      authorId,
      authors: selectedAuthors,
      title,
      shortDesc:
        plainDesc.slice(0, 150) + (plainDesc.length > 150 ? "..." : ""),
      desc: content,
      categories,
      tags,
      seoTitle,
      metaDescription,
      focusKeyphrase,
      url,
      photoCaption: caption,
      status: "draft",
      published: false,
      post_slug: postSlug,
    };

    // Handle file upload if there is a file
    if (file && file instanceof File) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the file
        const uploadResponse = await axios.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        newArticle.photo = uploadResponse.data.file; // Add filename to the article object
      } catch (err) {
        console.error("Error uploading image", err);
        toast.error("Error uploading image!");
        return; // Exit the function if file upload fails
      }
    }

    try {
      if (isUpdating) {
        await axios.put(`/posts/${currentArticleId}`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Draft updated successfully!");
      } else {
        const res = await axios.post(`/posts`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsUpdating(true);
        setCurrentArticleId(res.data._id);
        toast.success("Draft saved successfully!");
      }

      loadArticles();
    } catch (err) {
      console.error("Error saving draft", err);
      toast.error("Error saving draft");
    }
  };

  useEffect(() => {
    console.log("isUpdating set to: " + isUpdating);
    console.log("Current Article ID updated: " + currentArticleId);
  }, [isUpdating, currentArticleId]);

  const handlePublish = async () => {
    if (!validateFields()) return;
    const authorId = await getMainAuthor();
    const categories = await getCategories();
    const tags = await getTags();
    const plainDesc = extractText(content);
    const newArticle = {
      authorId,
      authors: selectedAuthors,
      title,
      shortDesc:
        plainDesc.slice(0, 150) + (plainDesc.length > 150 ? "..." : ""),
      desc: content,
      categories,
      tags,
      seoTitle,
      metaDescription,
      focusKeyphrase,
      url,
      photoCaption: caption,
      status: "published",
      published: true,
      post_slug: postSlug,
    };

    // Handle file upload if there is a file
    if (file && file instanceof File) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the file
        const uploadResponse = await axios.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        newArticle.photo = uploadResponse.data.file; // Add filename to the article object
      } catch (err) {
        console.error("Error uploading image", err);
        toast.error("Error uploading image!");
        return; // Exit the function if file upload fails
      }
    }

    try {
      if (isUpdating) {
        await axios.put(`/posts/${currentArticleId}`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Article updated successfully!");
      } else {
        await axios.post(`/posts`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Article published successfully!");
      }

      loadArticles();
    } catch (err) {
      console.error("Error publishing article", err);
      toast.error("Error publishing article");
    }
  };

  const handleCancelDraft = () => {
    setTitle("");
    setContent("");
    setPostSlug("");
    setSeoTitle("");
    setMetaDescription("");
    setFocusKeyphrase("");
    setCaption("");
    setUrl("");
    setSelectedCategories([]);
    setMainAuthor("");
    setSelectedAuthors([]);
    setSelectedTags([]);
    setCurrentArticleId("");
    setFile(null);
    setImage(null);
    setIsUpdating(false);
    navigate("/write");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    document.body.style.overflow = isSidebarOpen ? "auto" : "hidden"; // Toggle scrolling
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#C9CDD3", // desired background color
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2, // dropdown appears above other content
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF", // Background color of selected options
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000000", // Text color of selected options
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#272635", // Color of the dropdown arrow
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#272635", // Color of the separator between the arrow and the input
    }),
  };

  const handleImageUpload = (files) => {
    setSelectedImages(null);
    const newImage = files[0];
    setSelectedImages(newImage);
    setFile(newImage);
  };

  const removeImage = () => {
    setSelectedImages(null); // Set the selectedImages to null to remove the image
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && selectedImages) {
      setSelectedImages(null);
    }
  };

  const addTagToDatabase = async (newTag) => {
    try {
      const response = await axios.post(
        "/tags",
        { tag_name: newTag, tag_slug: createSlug(newTag) },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        const createdTag = response.data;
        setTags((prevTags) => [...prevTags, createdTag]);
      }
    } catch (error) {
      console.error("Error adding tag to the database:", error);
    }
  };

  const handleTagChange = (opts) => {
    console.log("inside");
    const newTags = opts.filter(
      (opt) => !tags.some((tag) => tag.value === opt.value)
    );
    newTags.forEach((newTag) => addTagToDatabase(newTag.label));

    setSelectedTags(
      opts.map((opt) => ({
        label: opt.label,
        value: decodeHtml(opt.value),
      }))
    );
  };

  const handleContentImages = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await axios.post(`/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const url = `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${res.data.file}`;
        const quillInstance = quillRef.current.getEditor();
        if (!quillInstance) {
          console.error("Quill instance not found.");
          return;
        }

        const range = quillInstance.getSelection();
        if (range) {
          quillInstance.insertEmbed(range.index, "image", url);
        } else {
          console.error("No selection in the editor.");
        }
      } catch (err) {
        console.error("Error uploading image: ", err);
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [
            { header: "1" },
            { header: "2" },
            {
              font: [],
            },
          ],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: handleContentImages,
        },
      },
    }),
    []
  );

  if (!user || !token || !isAuthorized) {
    return <div>Not Authorized!</div>;
  }

  return (
    <div className="Write">
      <div
        className={`dimmed-background ${isSidebarOpen ? "visible" : ""}`}
        onClick={toggleSidebar}
      ></div>

      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          {isSidebarOpen ? "→" : "←"}
        </button>
        <div className="sidebar-content">
          <div className="DraftManagement">
            <h2 style={{ textAlign: "center" }}>Drafts</h2>
            <ul>
              {drafts.map((draft) => (
                <li
                  key={draft._id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span style={{ textAlign: "left" }}>{draft.title}</span>

                  <div className="sideBarIcons" style={{ textAlign: "right" }}>
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{
                        marginRight: "15px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEditDraft(draft.post_slug)}
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{
                        marginRight: "15px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteDraft(draft._id)}
                    />
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{
                        marginRight: "15px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSwitchToPublish(draft._id)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="ArticleList">
            <h2 style={{ textAlign: "center" }}>Articles</h2>
            <div>
              <ul>
                {articles.map((art) => (
                  <li
                    key={art._id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Link
                      to={`/${art.post_slug}`}
                      style={{ textAlign: "left" }}
                    >
                      {art.title}
                    </Link>

                    <div
                      className="sideBarIcons"
                      style={{ textAlign: "right" }}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{
                          marginRight: "15px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditArticle(art.post_slug)}
                      />
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{
                          marginRight: "15px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteArticle(art._id)}
                      />
                      <FontAwesomeIcon
                        icon={faUnlock}
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSwitchToDraft(art._id)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="write">
        <div
          className="write-title"
          style={{ display: "flex", alignItems: "center", gap: "40px" }}
        >
          <p>Writing Page!</p>
          <button
            style={{
              width: "127px",
              height: "32px",
              borderRadius: "10px",
              backgroundColor: "#272635",
              color: "#B1E5F2",
              border: "none",
              cursor: "pointer",
              fontSize: "15px",
            }}
            onClick={handleCancelDraft}
          >
            + NEW ARTICLE
          </button>
        </div>
        {file ? (
          <>
            <img
              className="writeImg"
              src={URL.createObjectURL(file)}
              alt="Selected File"
            />
            <div className="writeFormGroup">
              <label>Image Caption: </label>
              <input
                type="text"
                placeholder="Image Caption"
                className="writeInput"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
          </>
        ) : image ? (
          <>
            <img className="writeImg" src={image} alt="Draft" />
            <div className="writeFormGroup">
              <label>Image Caption: </label>
              <input
                type="text"
                placeholder="Image Caption"
                className="writeInput"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <br />
        <form className="writeForm" onSubmit={(e) => e.preventDefault()}>
          <div className="writeFormGroup">
            <label>Author: </label>
            <div className="select">
              <Select
                options={authors}
                value={mainAuthor}
                onChange={(opt) => setMainAuthor(opt)}
                styles={customStyles}
                placeholder="Select Main Author *"
              />
            </div>
          </div>
          <div className="optionClass">
            <div className="categories">
              <label>Co-Authors: </label>
              <div className="select">
                <Select
                  options={authors.filter(
                    (author) => author.value !== mainAuthor?.value
                  )}
                  isMulti
                  value={selectedAuthors}
                  onChange={(opts) =>
                    setSelectedAuthors(
                      opts.map((opt) => ({
                        label: opt.label,
                        value: decodeHtml(opt.value),
                      }))
                    )
                  }
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Title: </label>
            <input
              type="text"
              placeholder="Title *"
              className="writeInput"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="writeFormGroup">
            <label>SEO Title: </label>
            <input
              type="text"
              placeholder="SEO Title *"
              className="writeInput"
              value={seoTitle}
              onChange={(e) => setSeoTitle(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && (
                <div className="tooltip">Start with keyphrase</div>
              )}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Meta Description: </label>
            <input
              type="text"
              placeholder="Meta Description *"
              className="writeInput"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">120-156 Words</div>}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Focus Keyphrase: </label>
            <input
              type="text"
              placeholder="Focus Keyphrase *"
              className="writeInput"
              value={focusKeyphrase}
              onChange={(e) => setFocusKeyphrase(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">3-5 Words</div>}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Slug: </label>
            <input
              type="text"
              placeholder="Slug"
              className="writeInput"
              value={postSlug}
              onChange={(e) => setPostSlug(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">Include Keyphrase</div>}
            </div>
          </div>

          <div className="optionClass">
            <div className="categories">
              <label>Categories: </label>
              <div className="select">
                <Select
                  options={categories}
                  isMulti
                  value={selectedCategories}
                  onChange={(opts) =>
                    setSelectedCategories(
                      opts.map((opt) => ({
                        label: opt.label,
                        value: decodeHtml(opt.value),
                      }))
                    )
                  }
                  styles={customStyles}
                  placeholder="Select Categories *"
                />
              </div>
            </div>
            <div className="tags">
              <label>Tags:</label>
              <div className="select">
                <CreatableSelect
                  isMulti
                  options={tags}
                  value={selectedTags}
                  onChange={handleTagChange}
                  styles={customStyles}
                  placeholder="Select or create tags"
                />
              </div>
            </div>
          </div>

          <div className="featured-images">
            <label>Featured Image:</label>
            <div
              className="featured-images-container"
              onKeyDown={handleKeyDown}
              tabIndex={0}
            >
              <div className="image-preview">
                {selectedImages && (
                  <div className="image-wrapper">
                    <img
                      src={URL.createObjectURL(selectedImages)}
                      alt={`preview`}
                    />
                    <button className="remove-image-btn" onClick={removeImage}>
                      ✖️
                    </button>
                  </div>
                )}
              </div>
              <div className="inside-continer">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e.target.files)}
                  className="image-featured-images"
                />
                <span className="upload-icon">+</span>
              </div>
            </div>
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && (
                <div className="tooltip">Visible on the homepage</div>
              )}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Content:</label>
            <ReactQuill
              ref={quillRef}
              style={{ border: "none" }}
              value={content}
              onChange={setContent}
              modules={modules}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              className="writeText"
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">Minimum 500 Words</div>}
            </div>
          </div>
          <div className="writeFormGroup-buttons">
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{
                marginRight: "15px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={handleCancelDraft}
            />
            <button className="writeSubmit" onClick={handlePublish}>
              {"Publish"}
            </button>
            <button className="writeSubmitDraft" onClick={handleSaveAsDraft}>
              Save as Draft
            </button>
          </div>
        </form>
        <SeoAnalyser
          seoTitle={seoTitle}
          metaDescription={metaDescription}
          focusKeyphrase={focusKeyphrase}
          content={content}
          postSlug={postSlug}
          caption={caption}
          previousArticles={articles}
        />
      </div>
    </div>
  );
}
