import React, { useContext, useEffect, useState } from "react";
import "./genrePreference.css";
import { Context } from "../../../context/Context";
import { toast } from "react-toastify";
import axios from "axios";
import { decodeHtml } from "../../../utils/utils";

const GenrePreference = () => {
  const { user, token } = useContext(Context);
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);

  useEffect(() => {
    const getGenres = async () => {
      try {
        const res = await axios.get("/categories");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setGenres(res.data);
      } catch (err) {
        console.error("Error fetching genres! " + err);
        toast.error("Error Fetching Genres!");
      }
    };
    const getUserGenres = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setSelectedGenres(res.data.genres);
      } catch (err) {
        console.error("Error fetching User Genres! " + err);
        toast.error("Error Fetching Favorite Genres!");
      }
    };
    getGenres();
    getUserGenres();
  }, [user.userid]);

  const toggleGenreSelection = (genreId) => {
    // Check if the genre is already selected
    const index = selectedGenres.indexOf(genreId);
    if (index !== -1) {
      // If selected, remove it from the selected genres
      setSelectedGenres((prevGenres) =>
        prevGenres.filter((g) => g !== genreId)
      );
    } else {
      // If not selected, add it to the selected genres
      setSelectedGenres((prevGenres) => [...prevGenres, genreId]);
    }
  };

  const handleSaveGenres = async () => {
    try {
      await axios.put(
        `/users/${user._id}`,
        {
          genres: selectedGenres,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Updated!");
    } catch (error) {
      toast.error("Error Updating Preferences!");
    }
  };

  return (
    <div className="genre-preferences" id="genre-preferences">
      <div className="heading-class-genre">
        <h1
          className="heading-genre"
          style={{ fontSize: "24px", fontWeight: "400" }}
        >
          Genre Preferences
        </h1>
        <h2
          className="subheading-genre"
          style={{ marginTop: "9px", fontSize: "20px", fontWeight: "400" }}
        >
          Select all that apply
        </h2>
      </div>
      <div className="options sidebarScrollable">
        {genres.map((genre) => (
          <div className="option" key={genre.cat_id}>
            <input
              type="checkbox"
              id={genre.cat_id}
              checked={selectedGenres.includes(genre.cat_id)}
              onChange={() => toggleGenreSelection(genre.cat_id)}
            />
            <label htmlFor={genre.cat_slug}>{decodeHtml(genre.cat_name)}</label>
          </div>
        ))}
      </div>
      <button
        type="submit"
        className="save-button"
        style={{
          width: "212px",
          height: "67px",
          marginLeft: "550px",
          marginTop: "30px",
          fontSize: "28px",
        }}
        onClick={handleSaveGenres}
      >
        Save
      </button>
    </div>
  );
};

export default GenrePreference;
