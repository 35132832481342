import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./preference.css";
import { Context } from "../../context/Context";
import { toast } from "react-toastify";
import { decodeHtml } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function Preference() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cats, setCats] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const { user: currentUser, token, dispatch } = useContext(Context);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("user_id");
    setUserId(userId);
    if (!token) {
      navigate("/login");
    } else if (currentUser && currentUser.userId) {
      if (Number(userId) !== currentUser.userId) {
        console.log(userId);
        console.log(currentUser);
        toast.error("Unauthorized Access!");
        navigate("/");
      }
    } else {
      const getUserDetails = async () => {
        try {
          const res = await axios.get(`/users/${userId}`);

          if (res.status !== 200) {
            toast.info(res.data.msg);
          }
          setUser(res.data);
        } catch (err) {
          console.error("Error fetching user! " + err);
          toast.error("Error Fetching User Data!");
        }
      };
      getUserDetails();
    }
  }, [location]);

  useEffect(() => {
    const getCats = async () => {
      try {
        const res = await axios.get("/categories");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setCats(res.data);
      } catch (err) {
        console.error("Error fetching genres! " + err);
        toast.error("Error Fetching Genres!");
      }
    };
    getCats();

    const getUsers = async () => {
      try {
        const res = await axios.get("/users");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const authors = res.data.filter((user) => user.isAuthor);
        setUsers(authors);
      } catch (err) {
        console.error("Error fetching writers! " + err);
        toast.error("Error Fetching Writers!");
      }
    };
    getUsers();
  }, []);

  const toggleGenreSelection = (genre) => {
    // Check if the genre is already selected
    const index = selectedGenres.indexOf(genre);
    if (index !== -1) {
      // If selected, remove it from the selected genres
      setSelectedGenres((prevGenres) => prevGenres.filter((g) => g !== genre));
    } else {
      // If not selected, add it to the selected genres
      setSelectedGenres((prevGenres) => [...prevGenres, genre]);
    }
  };

  const toggleAuthorSelection = (authorId) => {
    // Check if the author is already selected
    const index = selectedAuthors.indexOf(authorId);
    if (index !== -1) {
      // If selected, remove it from the selected authors
      setSelectedAuthors((prevAuthors) =>
        prevAuthors.filter((id) => id !== authorId)
      );
    } else {
      // If not selected, add it to the selected authors
      setSelectedAuthors((prevAuthors) => [...prevAuthors, authorId]);
    }
  };

  const handleSavePreferences = async () => {
    try {
      // Send PUT request to update user's preferences
      const res = await axios.put(
        `/users/${user._id}`,
        {
          genres: selectedGenres,
          authors: selectedAuthors,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      toast.success("Updated!");
      dispatch({ type: "UPDATE_SUCCESS", payload: res.data });
      // Redirect to the next page
      navigate("/");
    } catch (error) {
      console.error(error);
      toast.error("Error saving preferences:");
    }
  };

  return (
    <>
      {/* <div className="header-right-Preference">
        <Header />
      </div> */}
      <div className="container-preference">
        <Link to="/">
          <img
            src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/logo1.png"}
            className="logo-preference"
            alt="logo"
            style={{ width: "233px", height: "233px" }}
          />
        </Link>
        <ul className="progressbar-preference">
          <li className="done">
            <span>Sign Up</span>
          </li>
          <li className="done">
            <span>Username</span>
          </li>
          <li className="active">
            <span>Preference</span>
          </li>
          <li className="inactive">
            <span>The End</span>
          </li>
        </ul>

        <h1
          className="heading"
          style={{
            textAlign: "left",
            marginLeft: "100px",
            fontWeight: "400",
            fontSize: "36px",
          }}
        >
          Genre Preferences
        </h1>
        <h2
          className="subheading"
          style={{
            textAlign: "left",
            marginLeft: "100px",
            fontSize: "15px",
            fontWeight: "400",
          }}
        >
          Select all that apply
        </h2>
        <div className="options-preference">
          {cats.map((cat) => (
            <div key={cat.cat_id} className="option-preference">
              <input
                type="checkbox"
                id={cat.cat_slug}
                checked={selectedGenres.includes(cat.cat_id)}
                onChange={() => toggleGenreSelection(cat.cat_id)}
              />
              <label htmlFor={cat.cat_name}>{decodeHtml(cat.cat_name)}</label>
            </div>
          ))}
        </div>

        <h1
          className="heading"
          style={{
            textAlign: "left",
            marginLeft: "100px",
            marginTop: "20px",
            fontWeight: "400",
            fontSize: "36px",
          }}
        >
          Favourite Authors
        </h1>
        <h2
          className="subheading"
          style={{
            textAlign: "left",
            marginLeft: "100px",
            fontWeight: "400",
            fontSize: "15px",
          }}
        >
          Select all that apply
        </h2>
        <div className="card-container-preference">
          {users.map((user) => (
            <div
              key={user.userId}
              className="card-wrapper-preference"
              onClick={() => toggleAuthorSelection(user.userId)}
            >
              <div
                className={`card-preference ${
                  selectedAuthors.includes(user.userId) ? "selected" : ""
                }`}
              >
                <div className="card-inner-preference">
                  <div
                    className="card-front-preference"
                    style={{
                      background:
                        user && user.avatar_urls["full"] == null
                          ? `url(${user.profilePic + "&size=300"})`
                          : `url(${
                              process.env.REACT_APP_AWS_S3_IMAGE_URL +
                              user.avatar_urls["300"]
                            })`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="card-back-preference">
                    <p>{user.desc}</p>
                  </div>
                </div>
              </div>
              <h3 className="author-name-preference">
                {user.user_name.split(" ")[0]}
                <br />
                {user.user_name.split(" ").slice(1).join(" ")}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <div className="button-container">
        <button onClick={handleSavePreferences} className="next-button">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <span className="skip-text">SKIP</span>
      </div>
    </>
  );
}
