import "./categories.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { decodeHtml } from "../../utils/utils";
import { toast } from "react-toastify";
const PF = process.env.REACT_APP_AWS_S3_IMAGE_URL;

export default function Categories() {
  const [cats, setCats] = useState([]);

  useEffect(() => {
    const getCats = async () => {
      try {
        const res = await axios.get("/categories");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setCats(res.data);
      } catch (err) {
        console.error("Error fetching genres! " + err);
        toast.error("Error Fetching Genres!");
      }
    };

    getCats();
  }, []);

  return (
    <>
      <div className="header-container">
        <h1 className="header-title-categories">Categories</h1>
      </div>

      <div className="card-container">
        {cats.map((cat, index) => (
          <div className="card-categories" key={index}>
            <div className="image-container">
              <Link to={`/category/${cat._id}`}>
                <img
                  src={cat.cat_image.startsWith('https') ? cat.cat_image : `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${cat.cat_image}`}
                  alt="Card Image"
                />
                <div className="caption-categories">
                  {decodeHtml(cat.cat_name)}
                </div>
              </Link>
            </div>
            <div className="card-content">
              <h2>{cat.cat_slug}</h2>
              <p>{cat.cat_desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
