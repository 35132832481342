import React, { useState, useEffect } from "react";

export default function SeoAnalyser({
  seoTitle,
  metaDescription,
  focusKeyphrase,
  content,
  postSlug,
  caption,
  previousArticles,
}) {
  const [seoScore, setSeoScore] = useState(0);
  const [hasInput, setHasInput] = useState(false);

  const calculateSeoScore = () => {
    let score = 0;
    const maxScore = 100;

    // Check if there's any input
    if (
      seoTitle ||
      metaDescription ||
      focusKeyphrase ||
      content ||
      postSlug ||
      caption
    ) {
      setHasInput(true);
    } else {
      setHasInput(false);
      setSeoScore(0);
      return;
    }

    // Title length check
    if (seoTitle.length >= 40 && seoTitle.length <= 60) {
      score += 10;
    }

    // Meta description check
    if (metaDescription.length >= 120 && metaDescription.length <= 156) {
      score += 10;
    }

    // Focus keyphrase in title
    if (seoTitle.toLowerCase().includes(focusKeyphrase.toLowerCase())) {
      score += 10;
    }

    // Focus keyphrase in meta description
    if (metaDescription.toLowerCase().includes(focusKeyphrase.toLowerCase())) {
      score += 10;
    }

    // Focus keyphrase in content
    if (content.toLowerCase().includes(focusKeyphrase.toLowerCase())) {
      score += 10;
    }

    // Content length check
    const wordCount = content.split(/\s+/).length;
    if (wordCount >= 500) {
      score += 10;
    }

    // URL slug check
    if (postSlug.includes(focusKeyphrase.toLowerCase().replace(/\s+/g, "-"))) {
      score += 10;
    }

    // Image alt text check
    if (caption.toLowerCase().includes(focusKeyphrase.toLowerCase())) {
      score += 10;
    }

    // Internal and outbound links check
    const linkCount = (content.match(/<a href/g) || []).length;
    if (linkCount > 0) {
      score += 5;
    }

    // Focus keyphrase density check
    const keyphraseCount = (
      content
        .toLowerCase()
        .match(new RegExp(focusKeyphrase.toLowerCase(), "g")) || []
    ).length;
    const density = (keyphraseCount / wordCount) * 100;
    if (density >= 0.5 && density <= 2.5) {
      score += 5;
    }

    // Focus keyphrase in first paragraph
    const firstParagraph = content.split("</p>")[0];
    if (firstParagraph.toLowerCase().includes(focusKeyphrase.toLowerCase())) {
      score += 5;
    }

    // Image count check
    const imageCount = (content.match(/<img/g) || []).length;
    if (imageCount >= Math.floor(wordCount / 500)) {
      score += 5;
    }

    setSeoScore(Math.round((score / maxScore) * 100));
  };

  useEffect(() => {
    calculateSeoScore();
  }, [seoTitle, metaDescription, focusKeyphrase, content, postSlug, caption]);

  const getScoreColor = () => {
    if (seoScore >= 80) return "green";
    if (seoScore >= 50) return "orange";
    return "red";
  };
  
  return (
    <div className="seo-score-container">
      <h3>SEO Score</h3>
      {hasInput ? (
        <div
          className="score-circle"
          style={{ backgroundColor: getScoreColor() }}
        >
          {seoScore}%
        </div>
      ) : (
        <p>Start adding content to see your SEO score</p>
      )}
      <div className="seo-recommendations">
        {(seoTitle.length < 40 || seoTitle.length > 60) && (
          <p>Optimize your SEO title length (40-60 characters)</p>
        )}

        {(metaDescription.length < 120 || metaDescription.length > 156) && (
          <p>Adjust your meta description length (120-156 characters)</p>
        )}

        {!seoTitle.toLowerCase().includes(focusKeyphrase.toLowerCase()) && (
          <p>Include your focus keyphrase in the SEO title</p>
        )}

        {!metaDescription
          .toLowerCase()
          .includes(focusKeyphrase.toLowerCase()) && (
          <p>Include your focus keyphrase in the meta description</p>
        )}

        {!content.toLowerCase().includes(focusKeyphrase.toLowerCase()) && (
          <p>Use your focus keyphrase in the content</p>
        )}

        {content.split(/\s+/).length < 500 && (
          <p>Aim for at least 500 words in your content</p>
        )}

        {!postSlug.includes(
          focusKeyphrase.toLowerCase().replace(/\s+/g, "-")
        ) && <p>Include your focus keyphrase in the URL slug</p>}

        {!caption.toLowerCase().includes(focusKeyphrase.toLowerCase()) && (
          <p>Use your focus keyphrase in the image alt text</p>
        )}

        {(content.match(/<a href/g) || []).length === 0 && (
          <p>Add internal or outbound links to your content</p>
        )}

        {focusKeyphrase.split(/\s+/).length < 3 ||
          (focusKeyphrase.split(/\s+/).length > 5 && (
            <p>Your focus keyphrase should be 3-5 words long</p>
          ))}

        {previousArticles.some(
          (article) => article.focusKeyphrase === focusKeyphrase
        ) && <p>This focus keyphrase has been used in a previous article</p>}

        {!content
          .split("</p>")[0]
          .toLowerCase()
          .includes(focusKeyphrase.toLowerCase()) && (
          <p>Include your focus keyphrase in the introductory paragraph</p>
        )}

        {(content.match(/<img/g) || []).length <
          Math.floor(content.split(/\s+/).length / 500) && (
          <p>Add at least one image for every 500 words in your content</p>
        )}

        {!(content.match(/<h[23][^>]*>(.*?)<\/h[23]>/g) || []).some((h) =>
          h.toLowerCase().includes(focusKeyphrase.toLowerCase())
        ) && (
          <p>
            Include your focus keyphrase in at least one H2 or H3 subheading
          </p>
        )}
      </div>
    </div>
  );
}
